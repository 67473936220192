<template>
    <div class="tickets-table">
      <div class="header">
        <h1>{{ titleBarText }}</h1>
        <div v-if="!new_ticket_selected">
          <label for="filter">Filtro Estado</label>
          <select class="state_filter" v-model="chosen_state_filter" id="chosen_state" required @change="changeOnDisplayTickets">
            
            <option v-for="state in state_filters" :key="state" :value="state">
              {{ state }}
            </option>
          </select>
        </div>
        <div class="button-container">
          <button v-if="row_clicked && !user_involved_in_ticket" class="btn" @click="showModal = true">Finalizar Ticket</button>
          <button v-if="row_clicked && !user_involved_in_ticket" class="btn" @click="cancelTicket">Cancelar Ticket</button>
          <button class="btn" @click="handleClick">{{ buttonText }}</button>
        </div>
        
      </div>
      <br>
      <b-modal v-model="showModal" title="Finalização de Ticket" @ok="finalizeTicket">
      <!-- Input field -->
      <b-form-group label="Responsability Level" label-for="input-guilt">
        <b-form-input
          id="input-guilt"
          v-model="percentage_guilt"
          placeholder="Responsability Level"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Company Cost -->
      <b-form-group label="Company Cost" label-for="input-guilt">
        <b-form-input
          id="input-guilt"
          v-model="company_cost"
          placeholder="The cost the incident had to the company"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Textarea -->
      <b-form-group label="Justificação" label-for="textarea-justification">
        <b-form-textarea
          id="textarea-justification"
          v-model="justification"
          placeholder="Escreva a justificação"
          rows="4"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
      <table v-if="!new_ticket_selected">
        <thead>
          <tr>
            <th>ID</th>
            <th>Updated At</th>
            <th>Ticket Author</th>
            <th>Responsible</th>
            <th>Final Decider</th>
            <th>Incident</th>
            <th>Description</th>
            <th>Percentage Responsability</th>
            <th>Justification</th>
            <th>Company Cost</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ticket in tickets_on_display" :key="ticket.id" @click="handleRowClick(ticket)" :class="{highlighted: ticket.id === selectedRowId}" style="cursor: pointer;">
            <td>{{ ticket.id }}</td>
            <td>{{ formatDate(ticket.updated_at) }}</td>
            <td>{{ ticket.ticket_author_username }}</td>
            <td>{{ ticket.accused_person_username }}</td>
            <td>{{ ticket.final_decider_username }}</td>
            <td>{{ ticket.mistake }}</td>
            <td>{{ ticket.description }}</td>
            <td>{{ ticket.percentage_guilt }}</td>
            <td>{{ ticket.justification }}</td>
            <td>{{ ticket.company_cost }}</td>
            <td :style="getCellStyle(ticket.state)">{{ ticket.state }}</td>
          </tr>
        </tbody>
      </table>
      <NewAsneiraForm v-if="new_ticket_selected"/>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex/dist/vuex.common.js";
  import NewAsneiraForm from "./NewAsneiraForm.vue";
  console.log("list all asneiras")
  export default {
    data: function(){
        return {
            showModal: false,
            tickets: [],
            tickets_on_display: [],
            new_ticket_selected: false,
            finalize_ticket_selected: false,
            row_clicked: false,
            user_involved_in_ticket: false,
            selectedRowId: null,  // selected row is also the selected ticket id
            buttonText: "Novo Ticket",
            titleBarText: "Tabela de Tickets",
            current_user_id: null,
            percentage_guilt: null,
            company_cost: null,
            justification: "",
            state_filters: ['all', 'pending', 'cancelled', 'resolved'],
            chosen_state_filter: "all",
        }
    },
    components:{
      NewAsneiraForm
    },
    methods: {
      getCellStyle(status){
        if(status === 'pending'){
          return 'color: white; background-color: #0313fc';
        }
        else if(status === 'cancelled'){
          return 'background-color: #ecfc03';
        }
        else if(status === 'resolved'){
          return 'background-color: #03fc2c';
        }
        else{
          return '';
        }
      },
      changeOnDisplayTickets(){
        if(this.chosen_state_filter === 'all'){
          this.tickets_on_display = this.tickets;
        }
        else{
          this.tickets_on_display = [];
          for(let index = 0; index < this.tickets.length; index++){
            if(this.tickets[index].state === this.chosen_state_filter){
              this.tickets_on_display.push(this.tickets[index]);
            }
          }
        }
        
      },
      formatDate(isoString) {
        const date = new Date(isoString);
        return `${date.toLocaleDateString('en-GB')} ${date.toLocaleTimeString()}`;
      },
        async getTickets(){
            try{
                await this.$store.dispatch('getTicketsAsneiras');
                this.tickets = this.getAsneirasTickets;
                console.log(this.tickets);
            }catch(error){
                this.$swal({
                    icon: "error",
                    title: "oops",
                    text: error
                });
            }
        },
        async finalizeTicket(){
          // backend request body: tiket_id, final_decider_id(current user id), percentage_guilt, justification
          try {
            const payload = {
              ticket_id: this.selectedRowId, 
              final_decider_id: this.current_user_id, 
              percentage_guilt: this.percentage_guilt,
              justification: this.justification,
              company_cost: this.company_cost
            }
            this.percentage_guilt = "";
            this.company_cost = "";
            this.justification = "";
            await this.$store.dispatch('decideAsneiraTicket', payload);
            console.log(payload);
          } catch (error) {
            this.$swal({
                    icon: "error",
                    title: "oops",
                    text: error
                });
            return false;
          }
          await this.getTickets();
          this.changeOnDisplayTickets();
        },
        async cancelTicket(){
          // backend request body: tiket_id
          try {
            await this.$store.dispatch('cancelAsneiraTicket', this.selectedRowId);
          } catch (error) {
            this.$swal({
                    icon: "error",
                    title: "oops",
                    text: error
                })
          }
          await this.getTickets();
          this.changeOnDisplayTickets();
        },
        async handleClick(){
          console.log("Clicked me!");
          this.new_ticket_selected = !this.new_ticket_selected;
          if(this.new_ticket_selected){
            this.buttonText = "Lista de tickets";
            this.titleBarText = "Criação de Ticket";
          }
          else{
            this.buttonText = "Novo ticket";
            this.titleBarText = "Tabela de Tickets";
            await this.getTickets();
            this.changeOnDisplayTickets();
          }
        },
        handleRowClick(ticket){
          console.log(ticket);
          this.row_clicked = true;
          this.selectedRowId = ticket.id;
          this.company_cost = ticket.company_cost;
          console.log(this.selected_row_cost);
          if(ticket.ticket_author_id != this.current_user_id && ticket.accused_person_id != this.current_user_id && ticket.state === 'pending'){
            this.user_involved_in_ticket = false;
          }
          else{
            this.user_involved_in_ticket = true;
          }
          console.log("row ", this.selectedRowId, " clicked with id ", ticket.id);
        }
    },
    computed: {
        ...mapGetters(['getAsneirasTickets', 'cancelAsneiraTicket', 'decideAsneiraTicket'])
    },
    async created() {
        await this.getTickets()
        this.changeOnDisplayTickets();
        this.current_user_id = JSON.parse(localStorage.getItem('user')).user_id;
    }
    
  };
  </script>
  
  <style scoped>
  .tickets-table {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  background-color: #f5f5f5; /* Optional: Light background */
  border-bottom: 1px solid #ddd; /* Optional: Bottom border */
}

.header h1 {
  margin: 0;
  font-size: 24px;
}

.header .btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(255, 147, 147); /* Optional: Button background color */
  color: #fff; /* Optional: Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.header .btn:hover {
  background-color: rgba(255, 147, 147, 0.658); /* Optional: Button hover color */
}

.button-container button {
  margin: 0 10px;
}

.highlighted {
  background-color: #FF9393 !important;  /* !important para dar override a background color das linhas mais escuras default do estilo da tabela */
  color: #000; 
}

.header .state_filter{
  margin: 0 10px;
}
  </style>
  