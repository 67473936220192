

<template>
  <div>
    <AsneirasTable />
  </div>
</template>

<script>
  import AsneirasTable from "../components/AsneirasTable.vue";

  export default {
    components: {
      AsneirasTable,
    },
  };
</script>