import { render, staticRenderFns } from "./NewAsneiraForm.vue?vue&type=template&id=3a8d97f5&scoped=true"
import script from "./NewAsneiraForm.vue?vue&type=script&lang=js"
export * from "./NewAsneiraForm.vue?vue&type=script&lang=js"
import style0 from "./NewAsneiraForm.vue?vue&type=style&index=0&id=3a8d97f5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8d97f5",
  null
  
)

export default component.exports