<template>
    <div>
      <form @submit.prevent="submitForm">
        <!-- Accused Person (Spinner) -->
        <div>
          <label for="accused_person">Responsible</label>
          <select v-model="form.id_accused" id="accused_person" required>
            <option disabled value="">Select a person</option>
            <option v-for="person in people" :key="person.id" :value="person.id">
              {{ person.username }}
            </option>
          </select>
        </div>
  
        <!-- Mistake -->
        <div>
          <label for="mistake">Incident</label>
          <input
            type="text"
            id="mistake"
            v-model="form.mistake"
            placeholder="Enter the incident"
            required
          />
        </div>

        <!-- Company Cost -->
        <div>
          <label for="mistake">Company Cost</label>
          <input
            type="text"
            id="mistake"
            v-model="form.company_cost"
            placeholder="The cost the incident had to the company"
            required
          />
        </div>
  
        <!-- Description -->
        <div>
          <label for="description">Description</label>
          <textarea
            id="description"
            v-model="form.description"
            placeholder="Provide a detailed description"
            rows="3"
            required
          ></textarea>
        </div>

        <!-- Submit Button -->
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>


<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
    name: "NewAsneiraForm",
    data() {
        return {
            people: [],
            form: {
                id_accused: "",
                mistake: "",
                company_cost: "",
                description: "",
            },
        };
  },
  methods: {
    async submitForm() {
        // Add logic to handle form submission, e.g., send data to API
        try{
            await this.$store.dispatch('createAsneiraTicket', this.form);  
            this.people = this.getUsernamesAndIds;
            console.log("Form Data:", this.form);
        }catch(error){
            this.$swal({
                icon: "error",
                title: "oops",
                text: error
            })
        }
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Ticket criado com sucesso!`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.$parent.handleClick();
    },
    async getPeopleIds(){
        try{
            await this.$store.dispatch('getUsernamesAndIds');
            this.people = this.getUsernamesAndIds;
            console.log(this.people);
        }catch(error){
            this.$swal({
                icon: "error",
                title: "oops",
                text: error
            })
        }
    },
  },
  computed:{
    ...mapGetters(['getUsernamesAndIds', 'createAsneiraTicket'])
  },
  async created() {
    await this.getPeopleIds()
    }
};
</script>



<style scoped>
form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

label {
  font-weight: bold;
}

input,
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

button {
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
